<template>
  <section class="uk-section uk-section-default bgt" :id="id" v-if="content" :style="content.style">
      <div class="uk-container bg">
        <h3>Kontakt &amp; Anfahrt</h3>
        <div class="uk-grid-match" uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 500" uk-grid>
          <div class="uk-width-1-2@m" uk-scrollspy-class="uk-animation-slide-left">
            <div class="uk-card uk-card-body">
              <h3 class="uk-card-title">Kontaktdaten</h3>
              <img class="uk-animation-fade" width="200" height="66.6" src="../assets/img/logo.svg" alt="">
              <address>
                <p><span uk-icon="icon: location"></span>  Anschrift:</p>
                <p>
                  {{ content.street }}<br>
                  {{ content.plz }} {{ content.city }}
                </p>
                <p v-for="p in content.phone" :key="p.nr">
                  <span uk-icon="icon: phone"></span> 
                  Telefon: <a :href="'tel:' + p.nr">{{ p.nr }}</a>
                </p>
                <p>
                  <span uk-icon="icon: mail"></span> 
                  E-Mail: <a :href="'mailto:' + content.email" v-html="content.email"></a>
                </p>
              </address>
            </div>
          </div>
          <div class="uk-width-1-2@m" uk-scrollspy-class="uk-animation-slide-right">
            <div class="uk-card uk-card-body">
              <h3 class="uk-card-title">Kontaktformlar</h3>
              <p>Kontaktieren Sie uns. Bitte geben Sie mindestens eine Kontaktmöglichkeit (Telefon, E-Mail) an, damit wir antworten können.</p>
              <form class="uk-form-stacked uk-margin-left" ref="kontaktform">
                <label class="uk-form-label"
                  >Name<small> (erforderlich)</small></label
                >
                <input
                  class="uk-input uk-form-width-medium"
                  name="name"
                  type="text"
                  placeholder="Name"
                  v-model="form.name"
                  required
                  :class="{ 'uk-form-danger': this.formerror }"
                />
                <label class="uk-form-label"
                  >E-Mail</label
                >
                <input
                  class="uk-input uk-form-width-medium"
                  name="email"
                  type="email"
                  placeholder="E-Mail"
                  v-model="form.email"
                  :class="{ 'uk-form-danger': this.formerror }"
                />
                <label class="uk-form-label"
                  >Telefon</label
                >
                <input
                  class="uk-input uk-form-width-medium"
                  name="telefon"
                  type="text"
                  placeholder="Telefonnummer"
                  v-model="form.telefon"
                  :class="{ 'uk-form-danger': this.formerror }"
                />
                <label class="uk-form-label">Nachricht</label>
                <textarea
                  class="uk-textarea uk-form-width-medium"
                  name="nachricht"
                  v-model="form.nachricht"
                ></textarea>
              </form>
              <div v-if="success" class="uk-alert-success" uk-alert>
                <p><span uk-icon="icon: check"></span> Ihre Anfrage wurde versendet.</p>
              </div>
              <div v-if="formerror" class="uk-alert-danger" uk-alert>
                <p><span uk-icon="icon: warning"></span> Bitte alle erforderlichen Felder ausfüllen.</p>
              </div>
              <p uk-margin>
                <button class="uk-button uk-button-primary" @click="submitmail()">Absenden</button>
              </p>
            </div>
          </div>
          <div class="uk-width-1-2@m" uk-scrollspy-class="uk-animation-slide-left">
            <div class="uk-card uk-card-body">
              <h3 class="uk-card-title">Öffnungszeiten</h3>
                <p v-for="h in content.openinghours" :key="h.day"><span uk-icon="icon: calendar"></span>{{ h.day }}: {{ h.hours }}</p>
            </div>
          </div>
          <div class="uk-width-1-2@m" uk-scrollspy-class="uk-animation-slide-right">
            <div class="uk-card uk-card-body">
              <h3 class="uk-card-title">Anfahrt</h3>
              <div v-if="content.buslink">
                <h4>...mit dem Bus</h4>
                <p><a :href="content.buslink" target="_blank"><span uk-icon="icon: forward"></span> Buslinien {{ content.buslinien }}</a></p>
              </div>
              <div v-if="content.parken">
                <h4>Parken?</h4>
                <p v-html="content.parken"></p>
              </div>
              <h4>Karte</h4>
              <div v-if="!cookieconsent" class="uk-background-cover mapbackground">
                <p><span uk-icon="icon: question"></span> <small>Durch die Nutzung der Karte werden Daten (IP-Adresse) von der OpenStreetMap-Foundation gespeichert und wohlmöglich nach Großbritannien übertragen.</small></p>
                <p><router-link to="/impressum"><small>Mehr zum Datenschutz.</small></router-link></p>
                <p><button @click="showMap()" class="uk-button uk-button-primary">Karte anzeigen</button></p>
              </div>
              <div v-if="cookieconsent">
                <p>
                  <button @click="toggleMapOverlay()" class="uk-button uk-button-small" :class="[ mapopnv ? 'uk-button-primary' : 'uk-button-default']" :uk-tooltip="[ mapopnv ? 'ÖPNV ausblenden' : 'ÖPNV einblenden' ]">ÖPNV-Ansicht</button>
                </p>
                <iframe
                  width="425" 
                  height="350" 
                  frameborder="0" 
                  scrolling="no" 
                  marginheight="0" 
                  marginwidth="0" 
                  :src="maplink" 
                  style="border: 1px solid #999">
                </iframe>
            </div>
          </div>
        </div>        
      </div>
    </div>
  </section>
</template>

<script>

import axios from 'axios'

export default {
  name: 'Kontakt',
  props: ['id'],
  data () {
    return {
      content: null,
      error: null,
      form: {
        name: '',
        email: '',
        telefon: '',
        nachricht: '',
      },
      cookieconsent: false,
      maplink: 'about:blank',
      mapopnv: true,
      success: false,
      formerror: false,
      axioserror: ''
    }
  },
  methods: {
    pageInit: function () {
    axios.get(this.$baseUrl + this.id)
        .then( (res) => {
        this.content = res.data
        })
        .catch( (error) => {
        this.error = error
        })
    },
    submitmail () {
      if (this.$refs.kontaktform.checkValidity() && this.hasOneContactData()) {
        axios.post('https://www.schnittig.studio/api/email.php', this.form)
          .then( (res) => {
            this.response = res.data
            this.success = true
            setTimeout(this.resetSuccess, 6000);
            this.form = {
              name: '',
              email: '',
              telefon: '',
              nachricht: '',
            }
          })
          .catch( (error) => {
            if (error) {
              this.axioserror = error
            }
          })
        } else {
          this.formerror = true
          setTimeout(this.resetSuccess, 6000);
        }
      },
      resetSuccess: function () {
        this.success = false
        this.formerror = false
      },
      hasOneContactData: function () {
        return this.form.email != '' || this.form.telefon != ''
      },
      showMap: function() {
        this.cookieconsent = true
        this.toggleMapOverlay()
      },
      toggleMapOverlay: function() {
        var layer = 'mapnik'
        if (this.mapopnv) {
          this.mapopnv = false
        } else {
          this.mapopnv = true
          layer = 'opnvkarte'
        }
        this.$nextTick(() => {
          this.maplink = 'https://www.openstreetmap.org/export/embed.html?bbox=' + decodeURIComponent(this.content.osmbbox) + '&layer=' + layer + '&marker=' + decodeURIComponent(this.content.osmmarker)
        })
      }
    },
    mounted() {
        this.$nextTick(() => {
            this.pageInit()
        })
    }
}
</script>

<style>
address > p  {
  padding-left: 2em;
}
.mapbackground {
  background-image: url(../assets/img/mapconsentbackground.png);
  border: 1px solid #999;
  width: 375px;
  height: 300px;
  padding: 25px;
}
</style>