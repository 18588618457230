<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import UIKit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

UIKit.use(Icons)

export default {

}
</script>

<style lang="less">
@import "../node_modules/uikit/src/less/uikit.less";
@import "../node_modules/uikit/src/less/uikit.theme.less";

@global-link-color: #200b74;
@global-primary-background: #200b74;
@global-link-hover-color: #3e23ab;

@font-face {
  font-family: 'Bodoni Moda';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('assets/fonts/bodoni-moda-v4-latin-regular.woff2') format('woff2'),
       url('assets/fonts/bodoni-moda-v4-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('assets/fonts/montserrat-v15-latin-300.woff2') format('woff2'),
       url('assets/fonts/montserrat-v15-latin-300.woff') format('woff');
}
.fontheading {
    font-family: 'Bodoni Moda';
}

html {
    height: 100vh;
    min-height: 100%;
}

body {
  font-family: Montserrat;
}
body:after{
      content:"";
      position:fixed;
      top:0;
      height:100vh;
      left:0;
      right:0;
      z-index:-1;
      background:  #fff url(assets/img/schere.svg) center right fixed no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
}
h1, h2, h3, h4 {
  font-family: Bodoni Moda;
  color: rgb(205, 167, 0);
  margin-top: 1em;
}
.bg {
    background: rgba(255,255,255,0.9);
    border-radius:0.5em;
}
.bgt {
  background: transparent;
}
</style>
