<template>
<section class="uk-section uk-section-default bgt" :id="id" v-if="content" :style="content.style">
  <div class="uk-container" :class="{ bg: content.style.background == 'transparent', bgt: content.style.background != 'transparent' }">
    <h2>{{ content.title }}</h2>
    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow="animation: pull; autoplay: true">
      <ul class="uk-slideshow-items">
          <li v-for="i in content.img" :key="i.src">
              <img :src="getImgSrc(i.src)" alt="" uk-cover>
              <div v-if="i.title" class="uk-overlay uk-overlay-primary uk-position-bottom uk-text-center uk-transition-slide-bottom">
                <h3 class="uk-margin-remove" v-html="i.title"></h3>
                <p class="uk-margin-remove" v-html="i.text"></p>
              </div>
          </li>
      </ul>
      <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
      <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
    <a class="uk-float-right" href="" uk-tooltip="zurück nach oben" uk-totop uk-scroll=""></a>
  </div>
</section>

</template>

<script>

import axios from 'axios'

export default {
  name: 'Bilder',
    props: ['id'],
    data () {
        return {
        content: null,
        error: null
        }
    },
    methods: {
        pageInit: function () {
        axios.get(this.$baseUrl + this.id)
            .then( (res) => {
            this.content = res.data
            })
            .catch( (error) => {
            this.error = error
            })
        },
        getImgSrc: function (img) {
            return this.$imgUrl + img
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.pageInit()
        })
    }
}
</script>

<style>

</style>