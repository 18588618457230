<template>
  <div v-if="pages">
    <navigation :bm="pages.length > 1" />
    <nav-slider :pages="pages" />
    <component :id="p.id" v-for="p in pages" :key="p.id" :is="p.type" />
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'
import NavSlider from '@/components/NavSlider.vue'
import Splash from '@/components/Splash.vue'
import Artikel from '@/components/Artikel.vue'
import ArtikelCards from '@/components/ArtikelCards.vue'
import Bilder from '@/components/Bilder.vue'
import Bilderwand from '@/components/Bilderwand.vue'
import Kontakt from '@/components/Kontakt.vue'
import Foot from '@/components/Foot.vue'

import axios from 'axios'

export default {
  name: 'Home',
  components: {
    Navigation,
    NavSlider,
    Splash,
    Artikel,
    ArtikelCards,
    Bilder,
    Bilderwand,
    Kontakt,
    Foot
  },
  data () {
    return {
      pages: null,
      error: null,
    }
  },
  methods: {
    pageInit: function() {
      axios.get(this.$baseUrl + 'pages')
        .then( (res) => {
          this.pages = res.data
        })
        .catch( (error) => {
          this.error = error
        })
    }
  },
  mounted () {
    this.$nextTick(() => {
        this.pageInit()
    })
  }
}
</script>
