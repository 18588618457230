<template>
  <div id="offcanvas-nav" uk-offcanvas="mode: push; overlay: true">
    <div class="uk-offcanvas-bar">
      <ul class="uk-nav uk-nav-default">
        <li class="uk-nav-header">Navigation</li>
        <li v-for="s in navpages" :key="s.id"><a :href="'#'+s.id" uk-toggle="target: #offcanvas-nav" uk-scroll>{{ s.title }}</a></li>
        <li class="uk-nav-divider"></li>
        <li><router-link to="/impressum" uk-toggle="target: #offcanvas-nav">Impressum &amp; Rechtliches</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
    name: 'NavSlider',
    props:['pages'],
    data () {
        return {
            sections: null,
            error: null
        }
    },
    computed: {
      navpages: function () {
        try {
          const foot = this.pages.find(e => e.type === "Foot")
          return this.pages.filter(obj => obj !== foot)
        }
        catch (error) {
          return null
        }
      }
    }
}
</script>

<style>
</style>
