<template>
  <section class="uk-section bgt2w" :id="id" v-if="content">
    <hr class="uk-divider-icon">
    <div class="uk-container bgt">
        <div class="uk-flex uk-flex-middle">
        <div class="uk-card uk-card-default uk-card-body bgt" v-if="content.img.name"><img class="uk-float-right" :src="getImgSrc(content.img.name)" :width="content.img.width" :height="content.img.height"></div>
        <div class="uk-card uk-card-default uk-card-body bgt" v-if="content.text" v-html="content.text"></div>
        <div class="uk-card uk-card-default uk-card-body bgt"><router-link to="/impressum">Impressum &amp; Rechtliches</router-link></div>
        <div class="uk-card uk-card-default uk-card-body bgt"><a class="uk-float-right" href="" uk-totop uk-scroll=""></a></div>
        </div>
    </div>
  </section>
</template>

<script>

import axios from 'axios'

export default {
    name: 'Foot',
    props: ['id'],
    data () {
        return {
        content: null,
        ukstyle: 'uk-light',
        error: null
        }
    },
    methods: {
        pageInit: function () {
        axios.get(this.$baseUrl + this.id)
            .then( (res) => {
                this.content = res.data
            })
            .catch( (error) => {
                this.error = error
            })
        },
        getImgSrc: function (img) {
            return require('@/assets/img/' + img)
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.pageInit()
        })
    }
}
</script>
<style>
.uk-card {
    box-shadow: none;
}
.bgt2w {
    background-image: linear-gradient(rgba(255,255,255,0.6) 0%, #fff 40%);
}
</style>