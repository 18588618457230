<template>
<section class="uk-section uk-section-default bgt" :id="id" v-if="content" :style="content.style">
  <div class="uk-container" :class="{ bg: content.style.background == 'transparent', bgt: content.style.background != 'transparent' }">
    <h2>{{ content.title }}</h2>
    <div class="uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l" uk-grid="masonry: true">
    <div v-for="i in content.img" :key="i.src">
        <div class="uk-card uk-card-default uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-height-medium" :data-src="getImgSrc(i.src)" uk-img></div>
      </div>
    </div>
    <a class="uk-float-right" href="" uk-tooltip="zurück nach oben" uk-totop uk-scroll=""></a>
  </div>
</section>

</template>

<script>

import axios from 'axios'

export default {
  name: 'Bilder',
    props: ['id'],
    data () {
        return {
        content: null,
        error: null
        }
    },
    methods: {
        pageInit: function () {
        axios.get(this.$baseUrl + this.id)
            .then( (res) => {
            this.content = res.data
            })
            .catch( (error) => {
            this.error = error
            })
        },
        getImgSrc: function (img) {
            return this.$imgUrl + img
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.pageInit()
        })
    }
}
</script>

<style>

</style>