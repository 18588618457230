<template>
  <header :id="id" v-if="content">
    <div class="header-inner" uk-parallax="opacity: 1,0; blur: 30;">
      <img class="uk-animation-fade" width="500" height="166.6" src="../assets/img/logo.svg" alt="">
      <p v-if="content.city || content.street">
        {{ content.street }} &middot; {{ content.plz }} {{ content.city }}
      </p>
      <p v-if="content.phone">
        <span uk-icon="icon: phone"></span> Fon <a :href="'tel:' + content.phone ">{{ content.phone }}</a>
      </p>
    </div>
  </header>
</template>

<script>

import axios from 'axios'

export default {
  name: 'Splash',
  props: ['id'],
  data () {
    return {
      content: null,
      error: null,
    }
  },
  methods: {
    pageInit: function () {
      axios.get(this.$baseUrl + this.id)
        .then( (res) => {
          this.content = res.data
        })
        .catch( (error) => {
          this.error = error
        })
    }
  },
  mounted() {
    this.$nextTick(() => {
        this.pageInit()
    })
  }
}
</script>

<style>
header {
    height: 100vh;
}
.header-inner {
    max-width: 50%;
    margin: 0 auto;
    padding: 30vh 10vh 0vh 0vh;
    text-align: center;
}
.header-inner > p {
  font-family: Montserrat;
  font-size: 1.25em;
  color:rgb(205,167,0);
  text-shadow: 0px 0px 1px #fff;
  text-transform: uppercase;
}
.gopen {
  margin: 5em auto 0em;
  width: 12em;
  font-size:1.5em;
  height: 3em;
  border-radius: 0.5em;
  background-image: linear-gradient(90deg, #cca600, #F8CE17, #cca600, #F8CE17);
  padding: 1em;
  text-align: center;
  transform: rotate(-5deg);
  color:black;
}
</style>