<template>
  <section class="uk-section uk-section-default bgt" :id="id" v-if="content" :style="content.style">
        <div class="uk-container" :class="{ bg: content.style.background == 'transparent', bgt: content.style.background != 'transparent' }">
            <h2>{{ content.title }}</h2>
            <div v-for="p in content.parts" :key="p.id">
                <h3 v-if="p.subtitle">{{ p. subtitle }}</h3>
                <p><img v-if="p.img.name" class="uk-float-right" :src="getImgSrc(p.img.name)" :alt="p.subtitle" :width="p.img.width" :height="p.img.height">{{ p.text }}</p>
            </div>
            <div class="uk-clearfix"></div>
            <a class="uk-float-right" href="" uk-tooltip="zurück nach oben" uk-totop uk-scroll=""></a>
        </div>
  </section>
</template>

<script>

import axios from 'axios'

export default {
    name: 'Artikel',
    props: ['id'],
    data () {
        return {
        content: null,
        error: null
        }
    },
    methods: {
        pageInit: function () {
        axios.get(this.$baseUrl + this.id)
            .then( (res) => {
            this.content = res.data
            })
            .catch( (error) => {
            this.error = error
            })
        },
        getImgSrc: function (img) {
            return this.$imgUrl + img
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.pageInit()
        })
    }
}
</script>
<style>
</style>