<template>
  <section class="uk-section uk-section-default bgt" :id="id" v-if="content" :style="content.style">
        <div class="uk-container" :class="{ bg: content.style.background == 'transparent', bgt: content.style.background != 'transparent' }">
            <h2>{{ content.title }}</h2>
            <div :class="getWidthClass()" class="uk-grid-small uk-grid-match" uk-grid>
                <div v-for="card in content.cards" :key="card.id">
                    <div class="uk-card uk-card-default uk-card-body">
                        <h3 class="uk-card-title" v-html="card.title"></h3>
                        <p v-for="t in card.abs" :key="t.id" v-html="t.text"></p>
                    </div>
                </div>
            </div>
            <div class="uk-clearfix"></div>
            <a class="uk-float-right" href="" uk-tooltip="zurück nach oben" uk-totop uk-scroll=""></a>
        </div>
  </section>
</template>

<script>

import axios from 'axios'

export default {
    name: 'ArtikelCard',
    props: ['id'],
    data () {
        return {
        content: null,
        error: null
        }
    },
    methods: {
        pageInit: function () {
        axios.get(this.$baseUrl + this.id)
            .then( (res) => {
            this.content = res.data
            })
            .catch( (error) => {
            this.error = error
            })
        },
        getImgSrc: function (img) {
            return require('@/assets/img/' + img)
        },
        getWidthClass: function() {
            return 'uk-child-width-1-' + this.content.cards.length + '@m';
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.pageInit()
        })
    }
}
</script>
<style>
</style>