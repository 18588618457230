<template>
  <div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
    <nav class="uk-navbar-container uk-navbar-transparent" uk-navbar>
      <div class="uk-navbar-left" v-if="bm">
        <a class="uk-navbar-toggle" uk-navbar-toggle-icon href="" uk-toggle="target: #offcanvas-nav"></a>
      </div>
      <div class="uk-navbar-center">
          
      </div>
      <div class="uk-navbar-right" v-if="content">
          <ul class="uk-navbar-nav uk-visible@m">
            <li v-for="c in content" :key="c.icon"><a :href="c.href" :uk-icon="'icon: ' + c.icon"></a></li>
          </ul>
          <ul class="uk-navbar-nav uk-hidden@m">
            <li>
              <a href=""><span uk-icon="icon: hashtag"></span></a>
              <div class="uk-navbar-dropdown">
                  <ul class="uk-nav uk-navbar-dropdown-nav">
                    <li v-for="c in content" :key="c.icon"><a href="" :uk-icon="'icon: ' + c.icon"></a></li>
                  </ul>
              </div>
          </li>
          </ul>
      </div>
    </nav>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'Navigation',
  props: ['bm'],
  data () {
    return {
      content: null,
      error: null
    }
  },
  methods: {
    pageInit: function () {
      axios.get(this.$baseUrl + "social")
        .then( (res) => {
          this.content = res.data
        })
        .catch( (error) => {
          this.error = error
        })
    }
  },
  mounted() {
    this.$nextTick(() => {
        this.pageInit()
    })
  }
};
</script>

<style>
.sbgc {
    background-color: rgba(205,167,0,0.2);
}
</style>